<template>
  <div>
    <v-row
      v-if="viewData.id"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
        xl="7"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
          >
            <v-card
              outlined
              class="rounded-lg"
              :class="{
                'grey lighten-3': !$vuetify.theme.dark,
                'grey darken-3': $vuetify.theme.dark,
              }"
            >
              <v-card-text>
                <table class="company-info-table">
                  <thead>
                    <tr>
                      <th class="w-50" />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ $t('Company Name') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.name }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Company Type') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.company_type.name }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Activity') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.activity }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Manager') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.manager }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Phone') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.phone }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Email') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.email }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Website') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.website }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Governorate') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.governorate.name }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('First Bank') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.first_bank.name }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Account Bank') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.account_bank.name }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Business Name Date') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.name_date }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Business Name Number') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.name_number }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Capital') }}</td>
                      <td class="font-weight-bold">
                        {{ $_number_format(viewData.capital) }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Registration Number') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.company_registration_number }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Registration Date') }}</td>
                      <td class="font-weight-bold">
                        {{ $_date_format(viewData.company_registration_date) }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Dos Number In Companies') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.dos_number_in_co }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('Dos Number In Tax Office') }}</td>
                      <td class="font-weight-bold">
                        {{ viewData.dos_number_in_tax_office }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
          >
            <v-row>
              <v-col cols="12">
                <v-card
                  outlined
                  class="rounded-lg"
                  :class="{
                    'grey lighten-3': !$vuetify.theme.dark,
                    'grey darken-3': $vuetify.theme.dark,
                  }"
                >
                  <v-card-text>
                    <table class="company-info-table">
                      <thead>
                        <tr>
                          <th class="w-50" />
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ $t('Supporter Accountant') }}</td>
                          <td class="font-weight-bold">
                            {{ viewData.s_accountant }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t('Worker Accountant') }}</td>
                          <td class="font-weight-bold">
                            {{ viewData.w_accountant }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t('Accountant Contract Date') }}</td>
                          <td class="font-weight-bold">
                            {{ viewData.accountant_contract_date }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t('Supporter Lawyer') }}</td>
                          <td class="font-weight-bold">
                            {{ viewData.s_lawyer }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t('Worker Lawyer') }}</td>
                          <td class="font-weight-bold">
                            {{ viewData.w_lawyer }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t('Lawyer Contract Date') }}</td>
                          <td class="font-weight-bold">
                            {{ viewData.lawyer_contract_date }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t('Manager/Shareholder Business No.') }}</td>
                          <td class="font-weight-bold">
                            {{ viewData.manager_shareholder_business_no }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card
                  outlined
                  class="rounded-lg"
                >
                  <v-card-text>
                    <v-row
                      no-gutters
                      class="mt-3"
                    >
                      <v-col
                        cols="12"
                        class="font-weight-bold pb-2"
                      >
                        {{ $t('Shareholders') }}
                      </v-col>

                      <v-col
                        v-for="(shareholder, index) in viewData.shareholders"
                        :key="shareholder.id"
                        cols="12"
                        class="mt-1 mb-1"
                      >
                        <div
                          class="d-flex justify-space-between align-center rounded px-2 py-1"
                          :style="`background-color: ${shareholderColors[index]}`"
                        >
                          <!-- :class="{
                            'green lighten-3': index == 0 && !$vuetify.theme.dark,
                            'blue lighten-3': index == 1 && !$vuetify.theme.dark,
                            'warning lighten-3': index == 2 && !$vuetify.theme.dark,
                            'green darken-1': index == 0 && $vuetify.theme.dark,
                            'blue darken-1': index == 1 && $vuetify.theme.dark,
                            'warning darken-1': index == 2 && $vuetify.theme.dark,
                          }" -->
                          <div>
                            <v-avatar size="34">
                              <img
                                :src="shareholder.profile_picture ? `${$_file_path()}${shareholder.profile_picture}?token=${$_auth_token()}`: $_profile_placeholder()"
                                :alt="shareholder.username"
                              >
                            </v-avatar>
                            {{ shareholder.first_name }}
                          </div>

                          <div>
                            %{{ shareholder.share_percentage }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      viewData: {},
      shareholderColors: [
        '#4CAF506F',
        '#2196F36F',
        '#FF98006F',
        '#0096886F',
        '#3F51B56F',
        '#E91E636F',
        '#9C27B06F',
        '#673AB76F',
      ]
    }
  },

  mounted() {
    this.getCompanyData()
  },

  methods: {
    async getCompanyData() {
      await axios.get(`company/${this.$route.params.id}/`).then(res => {
        this.viewData = res.data
      })
    },

    printInfo() {
      let content = {
        content: '',
        css: ''
      }

      content.content = `
        <div style="width: 100%" class="px-6" class="white">
          <table
            cellspacing="0"
            style="width: 100%; border: 1px solid #aaa; border-radius: 2px; border-bottom: 0px"
          >
            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Name')}:</b> ${this.viewData.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Company Type')}:</b> ${this.viewData.company_type.name}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Governorate')}:</b> ${this.viewData.governorate.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('First Bank')}:</b> ${this.viewData.first_bank.name}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Account Bank')}:</b> ${this.viewData.account_bank.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Business Name Date')}:</b> ${this.viewData.name_date}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Business Name Number')}:</b> ${this.viewData.name_number ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Capital')}:</b> ${this.$_number_format(this.viewData.capital)}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Registration Number')}:</b> ${this.viewData.company_registration_number ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Registration Date')}:</b> ${this.$_date_format(this.viewData.company_registration_date)}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Manager')}:</b> ${this.viewData.manager ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Phone')}:</b> ${this.viewData.phone}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Email')}:</b> ${this.viewData.email ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Website')}:</b> ${this.viewData.website ?? ''}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Supporter Lawyer')}:</b> ${this.viewData.s_lawyer ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Supporter Accountant')}:</b> ${this.viewData.s_accountant ?? ''}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Worker Lawyer')}:</b> ${this.viewData.w_lawyer ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Worker Accountant')}:</b> ${this.viewData.w_accountant ?? ''}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Dos Number In Companies')}:</b> ${this.viewData.dos_number_in_co ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Dos Number In Tax Office')}:</b> ${this.viewData.dos_number_in_tax_office ?? ''}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('SL')}:</b> ${this.viewData.sl ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('FCC')}:</b> ${this.viewData.fcc ?? ''}</td>
            </tr>

            <tr>
              <td colspan="2" class="bottom-border pa-2"><b>${this.$t('Activity')}:</b> ${this.viewData.activity ?? ''}</td>
            </tr>
          </table>
        </div>
      `

      content.css = `
        .bottom-border {
          border-bottom: 1px solid #aaa
        }
      `

      this.$_manual_print(content)
    }
  },
}
</script>

<style lang="scss">
.company-info-table tbody > tr > td {
  padding: 0.4rem 0.2rem;
}
</style>